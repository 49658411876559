import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivityConstants, ColumnType } from '../../../../shared/constants/ActivityConstants';
import { FindActivityService } from '../../../../core/services/find-activity.service';
import { ActivityGroup } from '../../../../shared/models/activity/activity-group.model';
import { NewActivityService } from '../../../../core/services/new-activity.service';
import { Season } from '../../../../shared/models/season.model';
import { ActivityStatus } from '../../../../shared/models/activity/activity-status.model';
import { ActivityType } from '../../../../shared/models/activity/activity-type.model';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import { Location } from '../../../../shared/models/location.model';
import { DatePipe } from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { RoleGuardService } from '../../../../core/guards/role-guard.service';
import { AG_ROLE } from '../../../../shared/constants/roles';
import { ActivityTypes } from '../../../../shared/constants/ActivityTypes';
import { Chain, ChainGroupModel } from '../../../../shared/models/chain';
import { IPagedResponse } from '../../../../shared/models/IPagedResponse';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { UtilsService } from '../../../../core/services/utils.service';
import { GenericActivityView } from '../../../../shared/models/activity/generic-activity-view.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivityDetailComponent } from '../activity-detail/activity-detail.component';
import { GraphService } from '../../../../core/graph/graph.service';
import { AdminService } from '../../../../core/services/admin.service';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {environment} from '../../../../../environments/environment';
import {rejects} from 'assert';

@Component({
	selector: 'app-manage-activity-group',
	templateUrl: './manage-activity-group.component.html',
	styleUrls: ['./manage-activity-group.component.scss']
})
export class ManageActivityGroupComponent implements OnInit {

	@ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;
  	public chainMultiFilterCtrl: FormControl = new FormControl();
  	public filteredChainsMulti: ReplaySubject<ChainGroupModel[]> = new ReplaySubject<
    ChainGroupModel[]
    >(1);
	public tooltipMessage = 'Select All / Unselect All';
	protected _onDestroy = new Subject<void>();

  @ViewChild('staMultiSelect', { static: true }) staMultiSelect: MatSelect;
  public staMultiFilterCtrl: FormControl = new FormControl();
  public filteredStaMulti: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

	isLoadingResults = false;
	locations: Location[] = [];
	seasons: Season[] = [];
	activityStatuses: ActivityStatus[];
	activityType: ActivityType[] = [];
	chainGroups: ChainGroupModel[] = [];
	chainRestricted = true;
	chains: Chain[] = [];

	tableColumns = ActivityConstants.MANAGE_GROUPS.DISPLAYED_COLUMNS;
	displayedColumns = [];
	dataSource = new MatTableDataSource<ActivityGroup>();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	sorting = [];
	resultsLength = 0;

	showNotification = false;
	messageList = [];

	searchForm: FormGroup;
	filterArgs = { active: true };

	config: GenericActivityView = {
		title: 'Manage Activity Groups',
		page: 'manage-group'
	}

	constructor(private findActivityService: FindActivityService,
				private newActivityService: NewActivityService,
				private graphService: GraphService,
				private adminService: AdminService,
				private msgBanner: MsgBannerService,
				private datePipe: DatePipe,
				private fb: FormBuilder,
				private roleGuardService: RoleGuardService,
				private dialog: MatDialog) {
		this.searchForm = this.fb.group({
      title: [null, Validators.pattern(environment.emojiRegEx), []],
			chainGroups: [null, [Validators.required]],
			type: [null, [Validators.required]],
			season: [null, [], []],
			location: [null, [], []],
      startBeginDate: [null, Validators.pattern(environment.emojiRegEx), []],
      startFinishDate: [null, Validators.pattern(environment.emojiRegEx), []],
      endBeginDate: [null, Validators.pattern(environment.emojiRegEx), []],
      endFinishDate: [null, Validators.pattern(environment.emojiRegEx), []],
			expiredOld: [false, [], []],
      activityStatus: [null, [Validators.required]]
		});
		this.displayedColumns = this.tableColumns.map(c => c.colDef);
	}

	ngOnInit(): void {
		this.showNotification = false;
		this.isLoadingResults = true;
		Promise.all(this.getDefaultValues()).then(() => {
			// this.getActivityGroups();
		});
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
	}

	getDefaultValues(): Promise<boolean>[] {
		const promiseList: Promise<boolean>[] = [];

		// acitivity types
		promiseList.push(
			new Promise((release, reject) => {
				this.newActivityService.getActivityType().subscribe(
					result => {
						this.activityType = result.filter(actType => actType.actTypeId != ActivityTypes.PERSONAL);
						const complianceIdx = this.activityType.findIndex(a => a.actTypeId === ActivityTypes.COMPLIANCE.valueOf());
						if (complianceIdx !== -1) {
						  this.searchForm.controls.type.setValue(this.activityType[complianceIdx].actTypeId);
						  this.searchForm.controls.type.updateValueAndValidity();
            }
						release(true);
					}, error => {
						if (error.status === 404) {
							this.msgBanner.addMsgError(this.messageList, error.error.message);
						} else {
							this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
						}
						this.showNotification = true;
						reject(false);
					}
				);
			})
		);


		// locations
		promiseList.push(
			new Promise((release, reject) => {
				this.newActivityService.getLocations().subscribe(
					result => {
						this.locations = result;
						release(true);
					}, error => {
						if (error.status === 404) {
							this.msgBanner.addMsgError(this.messageList, error.error.message);
						} else {
							this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
						}
						this.showNotification = true;
						reject(true);
					}
				);
			})
		);


		// seasons
		promiseList.push(
			new Promise((release, rejects) => {
				this.newActivityService.getSeasons().subscribe(
					result => {
						this.seasons = result;
						release(true);
					}, error => {
						if (error.status === 404) {
							this.msgBanner.addMsgError(this.messageList, error.error.message);
						} else {
							this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
						}
						this.showNotification = true;
						rejects(false);
					}
				);
			})
		);

		// activity statuses
		promiseList.push(
		  new Promise((release, reject) => {
          this.newActivityService.getActivityStatuses().subscribe(
            result => {
              this.activityStatuses = result;
              this.initActivityStatuses();
              release(true);
            }, error => {
              if (error.status === 404) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
              reject(false);
            }
          )
      })
    );

		// user's chain groups
		promiseList.push(
			new Promise((release, reject) => {
				const email = this.graphService.user.displayName;
				this.adminService.getChainGroupsByEmail(email).subscribe(
					(result : ChainGroupModel[]) => {
						this.chainGroups = result;
						this.initAccountDropDownList();
						release(true);
					},
					(error: any) => {
						this.chainGroups = []
						reject(false);
					}
				);
			})
		);

		// check if chain restricted
		promiseList.push(
			new Promise((release, reject) => {
				const email = this.graphService.user.displayName;
			  	this.adminService.getChainsByEmail(email).subscribe(
					response => {
						this.chains = response;
						this.chainRestricted = response.length === 0 ? false : true;
					release(true);
					}, error => {
						if (error.status === 404)
							this.msgBanner.addMsgError(this.messageList, error.error.message);
						else
							this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');

						this.showNotification = true;
						reject(error);
					}
			  	);
			})
		);

		return promiseList;
	}

	getActivityGroups() {
		var searchFields: any = {}

		// pagination
		var pageIndex: number, pageSize: number;
		if (this.paginator) {
			pageIndex = this.paginator.pageIndex;
			pageSize = this.paginator.pageSize;
		} else {
			pageIndex = 0;
			pageSize = 10;
		}

		if (this.searchForm.controls.title.value)
			searchFields.title = this.searchForm.controls.title.value

		// check if selected or restricted
		var chainGrps: ChainGroupModel[] = this.searchForm.controls.chainGroups.value;
    if (this.chainRestricted && (!chainGrps || chainGrps.length === 0)) {
      this.searchForm.controls.chainGroups.setValue(this.chainGroups);
      this.searchForm.controls.chainGroups.updateValueAndValidity();
      chainGrps = this.searchForm.controls.chainGroups.value;
    }

		if (chainGrps && chainGrps.length > 0) {
      searchFields.chains = chainGrps.map(c => c.chains).join(",")
      searchFields.chainGrpId = chainGrps.map(c => c.groupId);
    }

		if (this.searchForm.controls.type.value)
			searchFields.actType = this.searchForm.controls.type.value

		if (this.searchForm.controls.activityStatus.value)
			searchFields.sta = this.searchForm.controls.activityStatus.value

		if (this.searchForm.controls.season.value)
			searchFields.seas = this.searchForm.controls.season.value

		if (this.searchForm.controls.location.value)
			searchFields.loc = this.searchForm.controls.location.value

		if (this.searchForm.controls.startBeginDate.value)
			searchFields.startBeginDate = this.convertDate(this.searchForm.controls.startBeginDate.value)

		if (this.searchForm.controls.startFinishDate.value)
			searchFields.startFinishDate = this.convertDate(this.searchForm.controls.startFinishDate.value)

		if (this.searchForm.controls.endBeginDate.value)
			searchFields.endBeginDate = this.convertDate(this.searchForm.controls.endBeginDate.value)

		if (this.searchForm.controls.endFinishDate.value)
			searchFields.endFinishDate = this.convertDate(this.searchForm.controls.endFinishDate.value)

		searchFields.expired = this.searchForm.controls.expiredOld.value ? this.searchForm.controls.expiredOld.value : false;

		this.findActivityService.getActivityGroups(pageIndex, pageSize, this.sorting, searchFields).subscribe(
			(response: IPagedResponse<ActivityGroup>) => {
				this.dataSource = new MatTableDataSource<ActivityGroup>(response.content);
				this.resultsLength = response.totalElements;
				this.isLoadingResults = false;
			},
			(error) => {
				this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				this.showNotification = true;
			}
		);
	}

	getActivityType(actType: number): string {
		return this.activityType.find(a => a.actTypeId === actType)?.en;
	}

  	getSeason(seasId: number) {
    	if (seasId)
      		return this.seasons.find(a => a.seasId === seasId)?.en;

    	return '';
  	}

	getLocation(locId: number) {
		if (locId)
			return this.locations.find(a => a.locId === locId)?.en;

		return '';
	}

	changeSort(event: Sort) {
		this.sorting = [];
		this.sorting = this.getSorting(event);
		this.getActivityGroups();
	}

	getSorting(sortEvent: Sort): string[] {
		const sort = [];
		sort.push(sortEvent.direction === '' ? null : sortEvent.active + ',' + sortEvent.direction);
		return sort;
	}

	get columnType() {
		return ColumnType;
	}

	editSelected(row: ActivityGroup) {
		const data = {
			taskId: row.actGrp,
			page: this.config.page
		};

		var dialogRef = this.dialog.open(ActivityDetailComponent, {
			data: data
		});

		dialogRef.afterClosed().subscribe((result: any) => {
			if (result != null && result != undefined)
				this.onSearch();
		});
	}

	isTerrOrMerchManager() {
		if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
		  || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
		  return true;
		}
		return false;
	}

	get getActivityTypes() {
		return ActivityTypes;
	}

	get getEnvironment() {
	  return environment;
  }

	onSearch() {
		if (this.searchForm.invalid)
			return

		this.showNotification = false;
		this.messageList = [];
		this.paginator.pageIndex = 0;
		this.sorting = [];
		this.getActivityGroups();
	}

	onClear() {
		this.searchForm.reset();
		this.searchForm.controls.type.setValue(ActivityTypes.COMPLIANCE.valueOf());
		this.searchForm.controls.type.updateValueAndValidity();
	}

	onPageChange(event: PageEvent) {
		this.paginator.pageIndex = event.pageIndex;
		this.paginator.pageSize = event.pageSize;
		this.getActivityGroups();
	}

	convertDate(value: any) {
		if (value instanceof Date)
		  return value;

		if (!value._i['year'] && value._i.getTime() === value._d.getTime())
		  return value.toDate();

		return UtilsService.dateFromServer(value.toDate());
	}

	toggleChainsSelectAll(selectAllValue: boolean) {
		this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
		  .subscribe(val => {
			if (selectAllValue) {
			  this.searchForm.controls.chainGroups.patchValue(val);
			} else {
			  this.searchForm.controls.chainGroups.patchValue([]);
			}
		});
	}

	private initAccountDropDownList() {
		this.filteredChainsMulti.next(this.chainGroups);
		this.chainMultiFilterCtrl.valueChanges
		  .pipe(takeUntil(this._onDestroy))
		  .subscribe(() => {
			this.filterChainsMulti();
		  });
	}

	protected filterChainsMulti() {
		// get the search keyword
		let search = this.chainMultiFilterCtrl.value;
		if (!search) {
		  this.filteredChainsMulti.next(this.chainGroups);
		  return;
		} else {
		  search = search.toLowerCase();
		}
		// filter the chains
		this.filteredChainsMulti.next(
		  this.chainGroups
			.filter(
			  (chainGrp) =>
				  chainGrp.desc
				  .toLowerCase()
				  .indexOf(search) > -1
			)
		);
	}

  onChange(event: MatSelectChange) {
    if (event.value?.length === 0) {
      this.searchForm.controls.activityStatus.setValue(null);
    }
  }

  toggleStatusSelectAll(selectAllValue: boolean) {
    this.filteredStaMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.searchForm.controls.activityStatus.patchValue(val);
        } else {
          this.searchForm.controls.activityStatus.patchValue([]);
        }
      });
  }

  private initActivityStatuses() {
    this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId));

    this.staMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStaMulti();
      });
  }

  protected filterStaMulti() {
    if (!this.activityStatuses) {
      return;
    }
    // get the search keyword
    let search = this.staMultiFilterCtrl.value;
    if (!search) {
      this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredStaMulti.next(
      this.activityStatuses
        .filter(
          (sta) =>
            sta.en.toLowerCase().indexOf(search) > -1
        ).map(sta => sta.actStaId)
    );
  }

  getActivityStatusById(staId: number) {
    return this.activityStatuses.find((sta: ActivityStatus) => sta.actStaId === staId)?.en;
  }
}
