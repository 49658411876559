import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {Activity} from '../../shared/models/activity/activity.model';
import {UtilsService} from './utils.service';
import {ActivityGroupView, ActivityView} from '../../shared/models/activity/activity-view.model';
import {Response} from '../../shared/models/activity/response.model';
import { ActivityGroup, ActivityGroupUpdates } from '../../shared/models/activity/activity-group.model';
import { StringResponse } from '../../shared/models/string-response';

@Injectable()
export class FindActivityService {
  constructor(private http: HttpClient) { }

  getResponses(activityGroupId: string): Observable<Response[]> {
    return this.http.get<Response[]>(environment.apiResourceUri + 'activities/responses?activityGroupId=' + activityGroupId);
  }

  getActivities(taskId: string, activityStatus: number): Observable<any> {
    let endpoint = '';
    if (!activityStatus) {
      endpoint = 'activities/review/find';
    } else {
      endpoint = 'activities/find';
    }
    const url = environment.apiResourceUri + endpoint;
    let params: HttpParams = new HttpParams();
    params = params.append('taskId', taskId);
    return this.http.get<any>(url, {
      observe: 'response',
      params,
    });
  }

  deleteActivity(activityIds: string[]) {
    const url = environment.apiResourceUri + 'activities';
    return this.http.request('delete', url, { body: activityIds });
  }

  updateActivity(result: Activity, activityStatus: number): Observable<any> {
    let endpoint = '';
    if (!activityStatus) {
      endpoint = 'activities/review';
    } else {
      endpoint = 'activities';
    }
    const url = environment.apiResourceUri + endpoint;
    return this.http.patch<any>(url, result);
  }

  getData(pageIndex: number, pageSize: number, searchFields: any, taskStatus: number): Observable<IPagedResponse<ActivityView>> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          if (key === 'startBeginDate' || key === 'startFinishDate' || key === 'endBeginDate' || key === 'endFinishDate') {
            const date = UtilsService.dateToServerDate(searchFields[key]);
            params = params.append(
              key,
              Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
            );
          } else if (key === 'personResponsible' && searchFields[key]) {
            params = params.append(key, searchFields[key].usrId);
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }

    params = params.set('expiredOld', !!searchFields['expiredOld']);

    if (!pageIndex || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if (!pageSize || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    let endpoint = '';
    if (!taskStatus) {
      endpoint = 'activities/review';
    } else {
      endpoint = 'activities/search';
    }

    return this.http.get(environment.apiResourceUri + endpoint, { params })
      .pipe(map((res: IPagedResponse<ActivityView>) => {
          return res;
        })
      );
  }

  massUpdate(data: Activity[], updateResponse: boolean): Observable<Activity[]> {
    const url = environment.apiResourceUri + 'activities?updateResponse=' + updateResponse;
    return this.http.put<Activity[]>(url, data);
  }

  publishActivity(taskIds: string[]): Observable<string[]> {
    const url = environment.apiResourceUri + 'activities/publish';
    return this.http.put<string[]>(url, taskIds);
  }

  getStoreName(chain: string, str: string): Observable<string> {
    const url = environment.apiResourceUri + 'stores/name/' + chain + '/' + str;
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  getActivitiesByGroupId(actGrpId: string): Observable<ActivityGroupView> {
    return this.http.get<ActivityGroupView>(
			environment.apiResourceUri + 'activities/' + actGrpId);
  }

  getActivityGroups(pageIndex: number, pageSize: number, 
            sort: string[], searchFields: any): Observable<IPagedResponse<ActivityGroup>> {

    let params = null;
    params = new HttpParams();

    // pagination
		if (!pageIndex || typeof pageIndex === 'number')
      params = params.append('page', pageIndex);
    
    if (!pageSize || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    // sorting
    if (sort && sort.length > 0) {
      sort.forEach(s => {
        params = params.append('sort', s);
      });
    }

    // searchFields
		if (searchFields != null || typeof searchFields !== 'undefined') {
			Object.keys(searchFields).map(function (key) {
        if (key === 'startBeginDate' || key === 'startFinishDate' || key === 'endBeginDate' || key === 'endFinishDate') {
          const date = UtilsService.dateToServerDate(searchFields[key]);
          params = params.append(
            key,
            Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
          );
        } else {
				  params = params.append(key, searchFields[key]);
        }
			});
		}

    return this.http.get<IPagedResponse<ActivityGroup>>(
			environment.apiResourceUri + 'activities/groups',
			{ params: params }
		);
  }

  updateActivityGroup(data: ActivityGroupUpdates): Observable<any> {
    return this.http.patch<any>(
      environment.apiResourceUri + 'activities/group', data);
  }

  deleteActivityFromGroup(deletedIds: number[]): Observable<StringResponse> {
    return this.http.patch<StringResponse>(
      environment.apiResourceUri + 'activities/delete-activity', deletedIds
    );
  }

  checkIfResponseIsSelected(actGrpId: string, respId: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("actGrpId", actGrpId);
    params = params.append("respId", respId);

    return this.http.get<boolean>(
      environment.apiResourceUri + 'activities/check-response', { params }
    );
  }

  propagateAccountTerritoryChanges(): Observable<any> {
    return this.http.get<any>(
      environment.apiResourceUri + 'activities/propagate-account-changes'
    );
  }
}
